@import '../../variable';

.tb-area-wrap {
  position: relative;

  display: grid;
  grid-column: 1 / 3;

  @include md {
    grid-column: inherit;
  }

  .btn-control {
    margin-bottom: 10px;
    z-index: 3;
    button {
      display: inline-flex;
      align-items: center;
      border: 1px solid rgba(255, 255, 255, 0.7);
      padding: 10px 14px;
      font-size: 15px;
      text-transform: capitalize;
      color: rgba(255, 255, 255, 0.7);
      border-radius: 5px;

      @include sm {
        font-size: 14px;
      }
      + button {
        margin-left: 5px;
      }

      svg {
        margin-right: 3px;
      }

      &.on {
        background-color: $themColor2;
        border: 1px solid $themColor2;
        color: rgba(255, 255, 255, 1);
        font-weight: 500;
      }
    }
  }
}
.tb-wrap {
  position: relative;
  height: 500px;
  overflow-y: auto;
  z-index: 2;

  @include md {
    width: 100%;
    order: 2;
    margin: 0;
  }

  @include sm {
    height: 400px;
  }
  // overflow-y: auto;
  // position: relative;
  // margin-top: 70vh;
  // background-color: rgba(0, 0, 0, 0.86);

  table {
    position: relative;
    width: 100%;
    border-collapse: collapse;
    border-radius: 10px;
    * {
      color: #fff;
    }

    .circle {
      position: absolute;
      top: 50%;
      left: 5px;
      transform: translateY(-50%);
      background-color: rgba(255, 255, 255, 0.1);
      width: 20px;
      height: 20px;
      border-radius: 50%;
      display: inline-block;
      overflow: hidden;

      em {
        width: 100%;
        height: 100%;
        border-radius: 50%;
        position: absolute;
        display: inline-block;
        top: 0;
        left: 0;
        background-color: #78ffcd;
        transform-origin: center center;
      }
    }
    .td-rate {
      padding-left: 30px;
      padding-right: 5px;
      color: #78ffcd;
    }

    th,
    td {
      position: relative;
      padding: 10px;
      border: 1px solid rgb(65, 64, 66);
      font-size: 13px;

      @include sm {
        padding: 8px 6px;
        font-size: 12px;
      }

      @include xs {
        padding: 6px 4px;
        font-size: 11px;
      }
    }

    th:nth-child(2),
    td:nth-child(2) {
      // text-align: center;
      padding-right: 10px;
    }
    th {
      z-index: 2;
      position: sticky;
      top: -2px; /* Don't forget this, required for the stickiness */
      box-shadow: 0 2px 2px -1px rgba(0, 0, 0, 0.5);
      background-color: rgb(27, 27, 31);
      span {
        display: inline-block;
        min-height: 50px;
        vertical-align: middle;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }

    .red {
      color: $themeRed;
    }

    .blue {
      color: $themeBlue;
    }

    .alR {
      text-align: right;
    }

    .alC {
      text-align: center;
    }

    // 틀고정
    thead {
      th:first-child {
        position: -webkit-sticky; /* for Safari */
        position: sticky;
        top: -1px;
        left: -1px;
        z-index: 3;
      }
    }

    .td-country {
      min-width: 130px;
      font-size: 15px;
      font-weight: 600;
      position: -webkit-sticky; /* for Safari */
      position: sticky;
      left: -1px;
      z-index: 1;
      text-align: left !important;
      padding-left: 25px !important;
      border-left: 0 !important;
      background-color: #2e2e32 !important;

      &::after {
        content: '';
        width: 1px;
        height: 100%;
        position: absolute;
        top: 0;
        right: -0.5px;
        background-color: rgba(255, 255, 255, 0.2);
      }

      .num {
        position: absolute;
        top: 50%;
        left: 0px;
        font-size: 12px;
        opacity: 0.4;
        transform: translateY(-50%);

        @include md {
          font-size: 10px;
        }

        @include sm {
        }
      }

      .cnt-name {
        display: inline-block;
        min-width: 60px;
      }

      @include md {
        font-size: 13px;
      }
      @include sm {
        font-size: 11.5px;
      }
    }

    .flag {
      margin-right: 6px;
      img {
        width: 15px;
      }
    }
    .add {
      font-size: 11px;
      opacity: 0.5;
    }

    .perdata {
      display: flex;
      justify-content: space-between;
    }
    .circle-ico {
      position: relative;
      width: 16px;
      height: 16px;
      background-color: #333;
      border-radius: 50%;
      display: inline-block;

      span {
        width: 100%;
        height: 100%;
        background-color: red;
        border-radius: 50%;
        position: absolute;
        z-index: 0;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
  }
}
